/** @format */

import {
  Card,
  CardDetails,
  FleetDriverExtended,
  FleetExtended,
  GlobalSettingType,
} from "@roadflex/types";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React from "react";
import * as Yup from "yup";
import { Button, ButtonVariant } from "../../buttons";
import ErrorMessage from "../../error-message/errorMessage";

interface StripeCardUpdateModalProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  cardData: Card;
  globalSetting: GlobalSettingType;
  handleCardUpdate?: (val: CardDetails) => void;
  submittingRequest?: boolean;
  isAdmin?: boolean;
  driversList: FleetDriverExtended[];
  vehiclesList: FleetExtended[];
}

export const StripeCardUpdateModal = ({
  open,
  setOpen,
  cardData,
  handleCardUpdate,
  globalSetting,
  submittingRequest,
  isAdmin = false,
  driversList,
  vehiclesList,
}: StripeCardUpdateModalProps) => {
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: {
      driverId: cardData.fleetDriver[0]?.id || "",
      vehicleId: cardData.fleet[0]?.id || "",
      dailyLimit:
        (cardData?.cardSpending.find((data) => data.interval === "daily")
          ?.amount || 0) / 100 ||
        globalSetting?.dailyLimit / 100 ||
        undefined,
      weeklyLimit:
        (cardData?.cardSpending.find((data) => data.interval === "weekly")
          ?.amount || 0) / 100 || undefined,
      monthlyLimit:
        (cardData?.cardSpending.find((data) => data.interval === "monthly")
          ?.amount || 0) / 100 || undefined,
      transactionLimit:
        (cardData?.cardSpending.find(
          (data) => data.interval === "per_authorization",
        )?.amount || 0) / 100 ||
        globalSetting?.transactionLimit / 100 ||
        undefined,
      merchants: cardData.merchants,
      status: cardData.status || "inactive",
      enableSmsReceiptUpload: cardData.enableSmsReceiptUpload,
    },
    validationSchema: Yup.object().shape({
      // email: Yup.string().email("Invalid email"),
      dailyLimit: Yup.number()
        .positive("The number must be greater than 0!")
        .test(
          "Is GlobalSetting Extented",
          "Please lower your limit",
          (value) => (value || 0) <= globalSetting?.dailyLimit / 100,
        ),
      weeklyLimit: Yup.number()
        .positive("The number must be greater than 0!")
        .notRequired(),
      monthlyLimit: Yup.number()
        .positive("The number must be greater than 0!")
        .notRequired(),
      transactionLimit: Yup.number()
        .positive("The number must be greater than 0!")
        .test(
          "Is GlobalSetting Extented",
          "Please lower your limit",
          (value) => (value || 0) <= globalSetting?.transactionLimit / 100,
        ),
    }),
    onSubmit: async (value) => {
      const data = {
        cardId: cardData.id,
        driverId: value.driverId,
        vehicleId: value.vehicleId,
        status: value.status,
        monthly: value.monthlyLimit ? value.monthlyLimit * 100 : 0,
        weekly: value.weeklyLimit ? value.weeklyLimit * 100 : 0,
        daily: value.dailyLimit ? value.dailyLimit * 100 : 0,
        transaction: value.transactionLimit ? value.transactionLimit * 100 : 0,
        merchants: cardData.merchants,
        enableSmsReceiptUpload: value.enableSmsReceiptUpload,
      };
      if (handleCardUpdate) {
        handleCardUpdate(data);
      }
    },
  });

  const footer = (
    <div className="flex flex-row justify-end">
      {!isAdmin && (
        <Button
          variant={ButtonVariant.Gray}
          disabled={isAdmin || submittingRequest}
          loading={submittingRequest}
          className="ml-3 !mr-0"
          onClick={() => {
            handleSubmit();
          }}
        >
          Confirm
        </Button>
      )}
    </div>
  );

  return (
    <Dialog
      header={`Card Information: XXXX-XXXX-${cardData.lastFour}`}
      visible={open}
      className="w-[95%] sm:w-3/4 xl:lg:max-w-[1200px] lg:max-w-[750px] md:max-w-[500px]"
      footer={footer}
      onHide={() => setOpen(false)}
    >
      <form onSubmit={handleSubmit} className="w-full">
        <div className="flex items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          {Object.keys(cardData).length > 0 && (
            <div className="relative inline-block w-full">
              <div className="h-full text-center ">
                <div className="mt-2 space-y-6 lg:col-span-9">
                  <div className="flex flex-col">
                    <div className="flex mt-2 flex-grow-1">
                      <div className="flex flex-col w-full">
                        <div className="w-full">
                          <label
                            htmlFor="driverId"
                            className="block text-sm font-medium text-left text-blue-gray-900"
                          >
                            Driver
                          </label>
                          <select
                            value={values.driverId}
                            disabled={isAdmin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="driverId"
                            id="driverId"
                            className={
                              "block w-full pl-3 appearance-none pr-8 py-2 text-blue-gray-900 border rounded focus:outline-none focus:ring-blue-500 focus:shadow-none focus:border-blue-500 sm:text-sm mt-1 shadow-sm border-blue-gray-300"
                            }
                          >
                            <option value={""}>-- Select an option --</option>
                            {driversList?.map((driver) => (
                              <option key={driver.id} value={driver.id}>
                                {driver.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="w-full mt-4">
                          <label
                            htmlFor="vehicleId"
                            className="block text-sm font-medium text-left text-blue-gray-900"
                          >
                            Vehicle
                          </label>
                          <select
                            value={values.vehicleId}
                            disabled={isAdmin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="vehicleId"
                            id="vehicleId"
                            className={
                              "block w-full pl-3 appearance-none pr-8 py-2 text-blue-gray-900 border rounded focus:outline-none focus:ring-blue-500 focus:shadow-none focus:border-blue-500 sm:text-sm mt-1 shadow-sm border-blue-gray-300"
                            }
                          >
                            <option value={""}>-- Select an option --</option>
                            {vehiclesList?.map((vehicle) => (
                              <option key={vehicle.id} value={vehicle.id}>
                                {vehicle.fleetName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-xl font-medium text-blue-gray-900">
                        Spending Limits
                      </h2>
                    </div>
                    <div className="flex mt-4 flex-grow-1">
                      <div className="w-full">
                        <label
                          htmlFor="dailyLimit"
                          className="block text-sm font-medium text-left text-blue-gray-900"
                        >
                          Daily
                        </label>
                        <input
                          type="number"
                          name="dailyLimit"
                          id="dailyLimit"
                          value={values.dailyLimit}
                          disabled={isAdmin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="-"
                          className="block w-full mt-1 rounded-md shadow-sm border-blue-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        {touched.dailyLimit && errors.dailyLimit && (
                          <ErrorMessage error={errors?.dailyLimit} />
                        )}
                      </div>

                      <div className="w-full ml-6">
                        <label
                          htmlFor="weeklyLimit"
                          className="block text-sm font-medium text-left text-blue-gray-900"
                        >
                          Weekly
                        </label>
                        <input
                          type="number"
                          name="weeklyLimit"
                          id="weeklyLimit"
                          value={values.weeklyLimit}
                          disabled={isAdmin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="-"
                          className="block w-full mt-1 rounded-md shadow-sm border-blue-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        {touched.weeklyLimit && errors.weeklyLimit && (
                          <ErrorMessage error={errors?.weeklyLimit} />
                        )}
                      </div>
                      <div className="w-full ml-6">
                        <label
                          htmlFor="monthlyLimit"
                          className="block text-sm font-medium text-left text-blue-gray-900"
                        >
                          Monthly
                        </label>
                        <input
                          type="number"
                          name="monthlyLimit"
                          id="monthlyLimit"
                          value={values.monthlyLimit}
                          disabled={isAdmin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="-"
                          className="block w-full mt-1 rounded-md shadow-sm border-blue-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                        {touched.monthlyLimit && errors.monthlyLimit && (
                          <ErrorMessage error={errors?.monthlyLimit} />
                        )}
                      </div>
                    </div>

                    <div className="mt-6">
                      <h2 className="text-xl font-medium text-blue-gray-900">
                        Transaction Limits
                      </h2>
                      <p className="mt-4 text-sm font-medium text-left text-blue-gray-900">
                        Maximum per Transaction
                      </p>
                    </div>
                    <div className="">
                      <input
                        type="number"
                        name="transactionLimit"
                        id="transactionLimit"
                        value={values.transactionLimit}
                        onChange={handleChange}
                        disabled={isAdmin}
                        onBlur={handleBlur}
                        placeholder="-"
                        className="block w-full mt-1 rounded-md shadow-sm border-blue-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                      {touched.transactionLimit && errors.transactionLimit && (
                        <ErrorMessage error={errors?.transactionLimit} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </Dialog>
  );
};
