/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from "lodash";
import { Dialog } from "primereact/dialog";

export interface DetailLogsProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  logs: any;
  heading: any;
}

export const DetailEmailHistoryModal = ({
  open,
  logs,
  setOpen,
  heading,
}: DetailLogsProps) => {
  const renderFooter = () => {
    return (
      <div>
        {/* <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Close
        </button> */}
      </div>
    );
  };

  return (
    <Dialog
      header={heading}
      visible={open}
      style={{ width: "60vw", fontFamily: "inherit" }}
      footer={renderFooter()}
      onHide={() => setOpen(false)}
    >
      {isEmpty(logs || {}) && <div>No Data</div>}
      {!isEmpty(logs || {}) && (
        <div className="mt-4">
          <pre className="leading-3">
            <code className="text-xs">{JSON.stringify(logs, null, 4)}</code>
          </pre>
        </div>
      )}
    </Dialog>
  );
};
