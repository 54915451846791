import { ENUM_COMMENT_TYPE } from "@prisma/client";
import { AdminType, AgentActionData, TabsType } from "@roadflex/types";
import { convertISOStringToPSTDateTime } from "@roadflex/utils";
import { phoneFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../buttons";
import { newPaginationTemplate } from "../datatable/pagination-templates/new-pagination-template";
import { Select } from "../dropdowns";
import { AddCommentModal } from "../modals/add-comment-modal/add-comment-modal";

interface AgentActionsUIProps {
  admins: AdminType[];
  tabs: TabsType[];
  dropDownValue: string;
  setdropDownValue: (value: string) => void;
  callClientActionData: AgentActionData[];
  emailActionData: AgentActionData[];
  smsActionData: AgentActionData[];
  agentActionDataLoading: boolean;
}

const AgentActionsUI = (props: AgentActionsUIProps) => {
  const {
    admins,
    tabs,
    dropDownValue,
    setdropDownValue,
    callClientActionData,
    emailActionData,
    smsActionData,
    agentActionDataLoading,
  } = props;
  const [tabOpen, setTabOpen] = useState(1);
  const [selectedUserData, setSelectedUserData] = useState<{
    userId: string;
    email: string;
  } | null>(null);
  const [showAddComment, setShowAddComment] = useState(false);
  const agentOptions = [
    ...(admins?.map((admin) => {
      return { label: `${admin.firstName} ${admin.lastName}`, value: admin.id };
    }) || []),
    { label: "No Agent Assigned", value: "" },
  ];

  const phoneNumberTemplate = (rowData: AgentActionData) => {
    return <div>{phoneFormatter(rowData.phoneNumber)}</div>;
  };

  const nameTemplate = (rowData: AgentActionData) => {
    return (
      <div>
        {rowData.firstName} {rowData.lastName}
      </div>
    );
  };

  const onboardingStageTemplate = (rowData: AgentActionData) => {
    const stages: {
      [key: number]: string;
    } = {
      0: "Initiation",
      1: "Email Verification",
      2: "Personal Info",
      3: "Business Info",
      4: "Address Info",
      5: "Fleet Info",
      6:
        rowData?.customerType === "PREPAID" ||
        rowData?.customerType === "PREPAID_PLUS" ||
        rowData?.customerType === "PREPAID_ENTERPRISE"
          ? "Bank Connection (can skip)"
          : "Bank Connection",
      7: "Application Review",
    };

    const stage = stages[rowData.onBoardStatus];

    return (
      <div className="text-sm text-brown-500 whitespace-nowrap">
        <span className="font-bold">{rowData.onBoardStatus}</span>: {stage}
      </div>
    );
  };

  const addCommentButtonTemplate = (rowData: AgentActionData) => {
    return (
      <div className="flex items-center">
        <Button
          size={ButtonSize.Small}
          variant={ButtonVariant.Gray}
          onClick={() => {
            setSelectedUserData({ userId: rowData.id, email: rowData.email });
            setShowAddComment(true);
          }}
        >
          Add Comment
        </Button>
      </div>
    );
  };
  return (
    <div className="sm:rounded-md sm:overflow-hidden">
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex items-center">
              <Select
                value={dropDownValue}
                options={agentOptions}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => setdropDownValue(e.value)}
                placeholder="Select a agent"
                itemTemplate={(option) => {
                  return (
                    <span
                      className={`product-badge status-${option.value.toLowerCase()}`}
                    >
                      {option.label}
                    </span>
                  );
                }}
              ></Select>
              <div className="ml-4">
                Action for Date:{" "}
                {convertISOStringToPSTDateTime(moment().toISOString(), true)}
              </div>
            </div>
            <div className="mt-2 rounded-md">
              <div className="border-b border-brown-500">
                <nav className="flex -mb-px space-x-8 overflow-auto">
                  {tabs.map((tab) => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      className={classNames(
                        tabOpen === tab.id
                          ? "border-black text-black"
                          : "border-transparent hover:border-black",
                        "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        setTabOpen(tab.id);
                      }}
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>

              <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-6`}>
                <div className="flex flex-col my-2">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-md">
                    <DataTable
                      className="!bg-white custom-table !text-brown-500 !border-brown-500"
                      size="small"
                      stripedRows
                      value={callClientActionData}
                      loading={agentActionDataLoading}
                      paginator
                      rows={10}
                      dataKey="id"
                      sortOrder={-1}
                      paginatorTemplate={newPaginationTemplate}
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rowsPerPageOptions={[10, 20, 50]}
                      selectionPageOnly
                      responsiveLayout="scroll"
                      emptyMessage="No clients to call"
                      style={{ fontFamily: "inherit" }}
                    >
                      <Column
                        sortable
                        filterPlaceholder="Search by email"
                        field="email"
                        header="Email"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                      />
                      <Column
                        sortable
                        header="Name"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        body={nameTemplate}
                      />
                      <Column
                        sortable
                        sortField="phoneNumber"
                        field="phoneNumber"
                        header="Phone Number"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        body={phoneNumberTemplate}
                      />
                      <Column
                        sortable
                        field="onBoardStatus"
                        header="Stage"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        dataType="numeric"
                        body={onboardingStageTemplate}
                      />
                      <Column
                        header="Add Comment"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        body={addCommentButtonTemplate}
                      />
                    </DataTable>
                  </div>
                </div>
              </div>
              <div className={`${tabOpen === 2 ? `block` : `hidden`} mt-6`}>
                <div className="flex flex-col my-2">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-md">
                    <DataTable
                      className="!bg-white custom-table !text-brown-500 !border-brown-500"
                      size="small"
                      stripedRows
                      value={emailActionData}
                      loading={agentActionDataLoading}
                      paginator
                      rows={10}
                      dataKey="id"
                      sortOrder={-1}
                      paginatorTemplate={newPaginationTemplate}
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rowsPerPageOptions={[10, 20, 50]}
                      selectionPageOnly
                      responsiveLayout="scroll"
                      emptyMessage="No emails sent today"
                      style={{ fontFamily: "inherit" }}
                    >
                      <Column
                        sortable
                        filterPlaceholder="Search by email"
                        field="email"
                        header="Email"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                      />
                      <Column
                        sortable
                        header="Name"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        body={nameTemplate}
                      />
                      <Column
                        sortable
                        sortField="phoneNumber"
                        field="phoneNumber"
                        header="Phone Number"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        body={phoneNumberTemplate}
                      />
                      <Column
                        sortable
                        field="onBoardStatus"
                        header="Stage"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        dataType="numeric"
                        body={onboardingStageTemplate}
                      />
                      <Column
                        header="Add Comment"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        body={addCommentButtonTemplate}
                      />
                    </DataTable>
                  </div>
                </div>
              </div>
              <div className={`${tabOpen === 3 ? `block` : `hidden`} mt-6`}>
                <div className="flex flex-col my-2">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-md">
                    <DataTable
                      className="!bg-white custom-table !text-brown-500 !border-brown-500"
                      size="small"
                      stripedRows
                      value={smsActionData}
                      loading={agentActionDataLoading}
                      paginator
                      rows={10}
                      dataKey="id"
                      sortOrder={-1}
                      paginatorTemplate={newPaginationTemplate}
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rowsPerPageOptions={[10, 20, 50]}
                      selectionPageOnly
                      responsiveLayout="scroll"
                      emptyMessage="No sms sent today"
                      style={{ fontFamily: "inherit" }}
                    >
                      <Column
                        sortable
                        filterPlaceholder="Search by email"
                        field="email"
                        header="Email"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                      />
                      <Column
                        sortable
                        header="Name"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        body={nameTemplate}
                      />
                      <Column
                        sortable
                        sortField="phoneNumber"
                        field="phoneNumber"
                        header="Phone Number"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        body={phoneNumberTemplate}
                      />
                      <Column
                        sortable
                        field="onBoardStatus"
                        header="Stage"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        dataType="numeric"
                        body={onboardingStageTemplate}
                      />
                      <Column
                        header="Add Comment"
                        headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                        bodyClassName="text-brown-500 !py-2 text-sm"
                        body={addCommentButtonTemplate}
                      />
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAddComment && selectedUserData && (
        <AddCommentModal
          setOpen={setShowAddComment}
          open={showAddComment}
          userData={selectedUserData}
          type={ENUM_COMMENT_TYPE.SUPPORT}
        ></AddCommentModal>
      )}
    </div>
  );
};

export default AgentActionsUI;
