/** @format */

import { useReadCurrentAdmin } from "@roadflex/react-hooks";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Header from "../header/header";
import Navigation from "../navigation/navigation";
import { AllRoutes } from "./helpers";

function Layout({ children }: { children: JSX.Element }): JSX.Element {
  const router = useRouter();
  const { data, refetch } = useReadCurrentAdmin();

  useEffect(() => {
    refetch();
  }, [router?.asPath, refetch, data]);

  // current page info -> check helper file in layouts
  const curr = AllRoutes.find((f) => f.route === router?.route);

  if (curr?.dontShow) {
    return <>{children}</>;
  }
  if (curr?.authenticated && data?.readCurrentAdmin) {
    return (
      <>
        <div className="min-h-full">
          {/* Static sidebar for desktop */}
          <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
            <Navigation />
          </div>
          <div className="flex flex-col flex-1 min-h-screen bg-blue-100 lg:pl-64">
            <div className="relative z-10 flex flex-shrink-0 h-16 bg-blue-100 border-b border-gray-200 lg:border-none">
              <Header />
            </div>
            {children}
          </div>
        </div>
      </>
    );
  }
  return <div>{children}</div>;
}

export default Layout;
