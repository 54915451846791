/** @format */

import { useMutation } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { ENUM_COMMENT_TYPE } from "@prisma/client";
import { ADMIN_ADD_COMMENT } from "@roadflex/graphql";
import { humanizeEnum } from "@roadflex/utils";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import React, { useRef } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";

interface AddCommentModalProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  userData: {
    userId: string;
    email: string;
  };
  type: ENUM_COMMENT_TYPE;
}

export const AddCommentModal = ({
  open,
  setOpen,
  userData,
  type,
}: AddCommentModalProps) => {
  const cancelButtonRef = useRef(null);
  const [addAdminCommentFn, { loading }] = useMutation(ADMIN_ADD_COMMENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    isSubmitting,
    errors,
  } = useFormik({
    initialValues: {
      comment: "",
      type: type,
    },
    validationSchema: Yup.object().shape({
      comment: Yup.string().required("Comment is required"),
      type: Yup.string().required(),
    }),
    onSubmit: async (value) => {
      if (!userData) {
        toast.error("User not available");
        return;
      }
      try {
        await addAdminCommentFn({
          variables: {
            data: {
              comments: value.comment,
              userId: userData.userId,
              type: value.type,
            },
          },
        });
        toast.success("Comment Added Successfully");
        setOpen(false);
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    },
  });

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        disabled={isSubmitting}
        variant={ButtonVariant.Gray}
        size={ButtonSize.Normal}
        onClick={() => setOpen(false)}
        ref={cancelButtonRef}
      >
        Cancel
      </Button>
      <Button
        type={ButtonType.Button}
        variant={ButtonVariant.Gray}
        loading={loading}
        disabled={isSubmitting || loading}
        className="ml-3"
        onClick={() => {
          handleSubmit();
        }}
      >
        Add
      </Button>
    </div>
  );

  const renderHeader = () => {
    return (
      <div className="text-base">{`Add Comment for user ${userData.email}`}</div>
    );
  };

  return (
    <Dialog
      header={renderHeader}
      visible={open}
      style={{ width: "30vw" }}
      footer={footer}
      onHide={() => setOpen(false)}
    >
      <form onSubmit={handleSubmit} className="w-full">
        <div className="flex">
          <div className="w-full">
            <div className="mb-1 text-sm whitespace-nowrap">Comment:</div>
            <div className="relative">
              <textarea
                name="comment"
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classNames(
                  touched.comment && errors?.comment
                    ? "border-red-500 focus:border-red-500 border"
                    : "",
                  "block w-full h-auto pl-3 pr-8 py-2 border rounded focus:outline-none focus:ring-0 focus:shadow-none",
                )}
              />
              {touched.comment && errors?.comment && (
                <>
                  <Tooltip
                    target=".comment-error"
                    position="top"
                    className="red-tooltip"
                    content={errors.comment}
                  ></Tooltip>

                  <div className="absolute h-[38px] bottom-0 right-0 pr-3 flex items-center cursor-pointer">
                    <ExclamationCircleIcon
                      className="w-5 h-5 text-red-500 comment-error"
                      aria-hidden="true"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="flex justify-between mt-4 flex-nowrap">
              <div className="relative w-full mb-1 text-sm whitespace-nowrap">
                <div>Comment Type:</div>
                <select
                  // id="type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={`type`}
                  value={values.type}
                  className={classNames(
                    touched?.type && errors?.type
                      ? "border-red-500 focus:border-red-500 border-2"
                      : "",
                    "block w-full pl-3 pr-8 py-2 border rounded focus:outline-none focus:ring-0 focus:shadow-none",
                  )}
                >
                  {Object.keys(ENUM_COMMENT_TYPE)
                    .filter((key) => key === type)
                    .map((key) => (
                      <option value={key} key={key}>
                        {humanizeEnum(key)}
                      </option>
                    ))}
                </select>
                {touched.type && errors?.type && (
                  <>
                    <Tooltip
                      target=".type-error"
                      position="top"
                      className="red-tooltip"
                      content={errors.type}
                    ></Tooltip>

                    <div className="absolute h-[44px] bottom-0 right-0 pr-8 flex items-center cursor-pointer">
                      <ExclamationCircleIcon
                        className="w-5 h-5 text-red-500 type-error"
                        aria-hidden="true"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
