/* eslint-disable dot-notation */
/** @format */
import { useMutation, useQuery } from "@apollo/client";
import { ENUM_CREDIT_CYCLE_TYPE, ENUM_CUSTOMER_TYPE } from "@prisma/client";
import {
  READ_CURRENT_ADMIN,
  UserApprovalMutation,
  UserApprovalWithStripeMutation,
} from "@roadflex/graphql";
import { AdminType } from "@roadflex/types";
import { useFormik } from "formik";

export type UseApprovalValuesType = {
  userId?: string;
  userLeadId?: string;
  customerType?: ENUM_CUSTOMER_TYPE;
  creditCycleType: ENUM_CREDIT_CYCLE_TYPE;
  creditLimit: number;
  metaData?: JSON;
  numberOfCards: number;
  status: string;
};

type UseApprovalProps = {
  initialValues: UseApprovalValuesType;
  onSubmit: (val: any, err: any) => void;
};

export const useBusinessApproval = ({
  onSubmit,
  initialValues,
}: UseApprovalProps) => {
  const [saveApproval] = useMutation(UserApprovalMutation);

  const { handleChange, handleSubmit, values, isSubmitting, ...rest } =
    useFormik({
      initialValues,
      onSubmit: async (value) => {
        const data = {
          ...value,
          creditLimit: value.creditLimit * 100,
          numberOfCards: value.numberOfCards,
          customerType: value?.customerType || undefined,
        };
        try {
          const res = await saveApproval({
            variables: { data },
          });
          onSubmit(res, null);
        } catch (error) {
          onSubmit(null, error);
        }
      },
    });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export const useBusinessApprovalWithStripe = ({
  onSubmit,
  initialValues,
}: UseApprovalProps) => {
  // const [saveApproval] = useMutation(UserApprovalWithStripeMutation);
  const [saveApprovalWithStripe] = useMutation(UserApprovalWithStripeMutation);

  const { handleChange, handleSubmit, values, isSubmitting, ...rest } =
    useFormik({
      initialValues,
      onSubmit: async (value) => {
        const data = {
          ...value,
          creditLimit: value.creditLimit * 100,
          numberOfCards: value.numberOfCards,
          customerType: value?.customerType || undefined,
        };
        try {
          const res = await saveApprovalWithStripe({
            variables: { data },
          });
          onSubmit(res, null);
        } catch (error) {
          onSubmit(null, error);
        }
      },
    });
  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export function useReadCurrentAdmin() {
  const { data, ...rest } = useQuery<{
    readCurrentAdmin: AdminType;
  }>(READ_CURRENT_ADMIN, {
    fetchPolicy: "no-cache",
  });

  return { data, ...rest };
}
