/** @format */
import { useLazyQuery } from "@apollo/client";
import { ADMIN_EXPORT_USERS_DATA } from "@roadflex/graphql";

export function useAdminExportUsersData() {
  const [
    loadAdminExportUsersDataFn,
    { data: adminExportUsersData, loading: adminExportUsersDataLoading },
  ] = useLazyQuery(ADMIN_EXPORT_USERS_DATA, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    loadAdminExportUsersDataFn,
    adminExportUsersData,
    adminExportUsersDataLoading,
  };
}
