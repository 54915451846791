/** @format */
import {
  AdjustmentsIcon,
  ArchiveIcon,
  BellIcon,
  BookOpenIcon,
  CashIcon,
  ChartBarIcon,
  ClockIcon,
  CodeIcon,
  CogIcon,
  CollectionIcon,
  CurrencyDollarIcon,
  DocumentSearchIcon,
  HomeIcon,
  ScaleIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { ENUM_ADMIN_ROLES } from "@prisma/client";
import { useReadCurrentAdmin } from "@roadflex/react-hooks";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import Redirect from "../Redirect";
import NavigationUI from "./navigation.ui";

const navigationList = [
  [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: 1,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN],
    },
    {
      name: "Subscribers/Affiliates Program",
      href: "/subscribers",
      icon: UserIcon,
      current: 2,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN, ENUM_ADMIN_ROLES.SALES],
    },
    {
      name: "Applications",
      href: "/onboard",
      icon: ClockIcon,
      current: 3,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.SALES,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
        ENUM_ADMIN_ROLES.UNDERWRITER,
      ],
    },
    {
      name: "Pending Deposit/Set-up Fee",
      href: "/applications-pending-deposit",
      icon: CurrencyDollarIcon,
      current: 4,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.SALES,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
      ],
    },
    {
      name: "Approved Customers",
      href: "/clients",
      icon: ScaleIcon,
      current: 5,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN, ENUM_ADMIN_ROLES.UNDERWRITER],
    },
    {
      name: "Customer Information",
      href: "/customer-dashboard",
      icon: HomeIcon,
      current: 6,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
      ],
    },
    {
      name: "Payments",
      href: "/payments",
      icon: CashIcon,
      current: 7,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
      ],
    },
  ],
  [
    {
      name: "Global Settings",
      href: "/global-settings",
      icon: AdjustmentsIcon,
      current: 8,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN],
    },

    {
      name: "Notifications",
      href: "/notifications",
      icon: BellIcon,
      current: 9,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN],
    },
    {
      name: "Blogs",
      href: "/blogs",
      icon: BookOpenIcon,
      current: 10,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN],
    },
    {
      name: "Risks",
      href: "/risk",
      icon: DocumentSearchIcon,
      current: 11,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN, ENUM_ADMIN_ROLES.UNDERWRITER],
    },
    {
      name: "Collections",
      href: "/collections",
      icon: CollectionIcon,
      current: 12,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN, ENUM_ADMIN_ROLES.COLLECTIONS],
    },
  ],
  [
    {
      name: "Scripts",
      href: "/scripts",
      icon: CodeIcon,
      current: 13,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
      ],
    },
    {
      name: "Data Analysis",
      href: "/data-analysis",
      icon: ChartBarIcon,
      current: 14,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
      ],
    },
    {
      name: "Activity Logs",
      href: "/logs",
      icon: ArchiveIcon,
      current: 15,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN, ENUM_ADMIN_ROLES.UNDERWRITER],
    },
  ],
  [
    {
      name: "Team",
      href: "/team",
      icon: UserGroupIcon,
      current: 16,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN],
    },
    {
      name: "Account Settings",
      href: "/settings",
      icon: CogIcon,
      current: 17,
      canAccess: [
        ...Object.keys(ENUM_ADMIN_ROLES).map((key) => ENUM_ADMIN_ROLES[key]),
      ],
    },
  ],
];

/* eslint-disable-next-line */
export interface NavigationProps {}

export function Navigation(props: NavigationProps) {
  const [page, setPage] = useState(1);
  const { data, loading } = useReadCurrentAdmin();

  if (loading) {
    return <></>;
  }
  const { readCurrentAdmin } = data || {};

  if (isEmpty(readCurrentAdmin)) {
    return <Redirect path="/" />;
  }

  const roles = readCurrentAdmin?.roles || [];
  const navigation = navigationList.map((navBlock) => {
    return navBlock.filter((navItem) => {
      return navItem.canAccess.some((role) => roles.includes(role));
    });
  });

  return (
    <>
      <NavigationUI {...{ navigation, page, setPage }} />
    </>
  );
}

export default Navigation;
