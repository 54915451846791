/** @format */
import { useMutation } from "@apollo/client";
import { READ_USER_DETAILS, USER_UPDATE_BY_ADMIN } from "@roadflex/graphql";
import { useFormik } from "formik";

type Values = {
  updateSetting: string;
  id: string | null;
  userLeadId: string | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  ssn: string;
  business: any | null;
  setting: any | null;
  referralSetting: any | null;
  fleetLicense: string;
  statements: string[];
  irsLetter: string;
};

type useUserUpdateByAdminProps = {
  initialValues: Values;
  onSubmit: (val: any, err: any) => void;
};

export const useUserUpdateByAdmin = ({
  onSubmit,
  initialValues,
}: useUserUpdateByAdminProps) => {
  const [UserUpdateByAdminFn] = useMutation(USER_UPDATE_BY_ADMIN, {
    refetchQueries: [READ_USER_DETAILS],
  });

  const {
    handleChange: handleUpdateUserByAdminChange,
    handleSubmit: handleUpdateUserByAdminSubmit,
    values: updateUserByAdminValues,
    isSubmitting: isUpdateUserByAdminSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const data = { ...values };
      try {
        const res = await UserUpdateByAdminFn({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        // console.log(error);
        onSubmit(null, error);
      }
    },
  });

  return {
    updateUserByAdminValues,
    handleUpdateUserByAdminChange,
    handleUpdateUserByAdminSubmit,
    isUpdateUserByAdminSubmitting,
    ...rest,
  };
};
