/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { ENUM_MAIL_TYPE } from "@prisma/client";
import { EmailTemplateDetails } from "@roadflex/types";
import classNames from "classnames";
import { useFormik } from "formik";
import { Tooltip } from "primereact/tooltip";
import { Fragment, useRef } from "react";

type AddEditMailTypeModalProps = {
  onSaveMailType: ({
    mailType,
    templateName,
  }: {
    mailType: string;
    templateName: string;
  }) => void;
  editMailTypePopup: boolean;
  addedTemplates: EmailTemplateDetails[];
  setEditMailTypePopup: (editMailTypePopup: boolean) => void;
};

export const AddEditMailTypeModal = ({
  editMailTypePopup,
  onSaveMailType,
  setEditMailTypePopup,
  addedTemplates,
}: AddEditMailTypeModalProps) => {
  const cancelButtonRef = useRef(null);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    isSubmitting,
    errors,
    ...rest
  } = useFormik({
    initialValues: { mailType: "", templateName: "" },
    onSubmit: async (value) => {
      onSaveMailType(value);
      setEditMailTypePopup(false);
    },
  });
  return (
    <Transition.Root show={editMailTypePopup} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setEditMailTypePopup}
      >
        <form onSubmit={handleSubmit} className="w-full">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-md shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-center text-gray-900"
                  >
                    Add/Edit Mail Type
                  </Dialog.Title>
                  <div className="relative w-full mt-4">
                    <div className="mb-1 text-sm whitespace-nowrap">
                      Mail Type
                    </div>
                    <select
                      // id="type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="mailType"
                      value={values.mailType}
                      className={classNames(
                        touched?.mailType && errors?.mailType
                          ? "border-red-500 focus:border-red-500 border-2"
                          : "",
                        "block w-full disabled:opacity-50 pl-3 pr-8 py-2 border rounded focus:outline-none focus:ring-0 focus:shadow-none",
                      )}
                    >
                      <option value="" disabled hidden>
                        Select Mail Type
                      </option>
                      {(
                        Object.keys(ENUM_MAIL_TYPE) as Array<
                          keyof typeof ENUM_MAIL_TYPE
                        >
                      ).map((mailType, idx) => (
                        <option value={mailType} key={idx}>
                          {mailType}
                        </option>
                      ))}
                    </select>
                    {touched.mailType && errors?.mailType && (
                      <>
                        <Tooltip
                          target=".mailType-error"
                          position="top"
                          className="red-tooltip"
                          content={errors.mailType}
                        ></Tooltip>

                        <div className="absolute h-[44px] bottom-0 right-0 pr-8 flex items-center cursor-pointer">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500 mailType-error"
                            aria-hidden="true"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="relative w-full mt-4">
                    <div className="mb-1 text-sm whitespace-nowrap">
                      Template Name
                    </div>
                    <select
                      // id="type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="templateName"
                      value={values.templateName}
                      className={classNames(
                        touched?.templateName && errors?.templateName
                          ? "border-red-500 focus:border-red-500 border-2"
                          : "",
                        "block w-full disabled:opacity-50 pl-3 pr-8 py-2 border rounded focus:outline-none focus:ring-0 focus:shadow-none",
                      )}
                    >
                      <option value="" disabled hidden>
                        Select Template
                      </option>
                      {addedTemplates.length > 0 &&
                        addedTemplates?.map(
                          (item: { templateName: string }, idx: number) => (
                            <option value={item.templateName} key={idx}>
                              {item.templateName}
                            </option>
                          ),
                        )}
                    </select>
                    {touched.templateName && errors?.templateName && (
                      <>
                        <Tooltip
                          target=".templateName-error"
                          position="top"
                          className="red-tooltip"
                          content={errors.templateName}
                        ></Tooltip>

                        <div className="absolute h-[44px] bottom-0 right-0 pr-8 flex items-center cursor-pointer">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500 templateName-error"
                            aria-hidden="true"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="submit"
                    className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:col-start-2 sm:text-sm"
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setEditMailTypePopup(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
};
