import {
  ENUM_NOTIFICATION_TYPE,
  ENUM_SENDGRID_TEMPLATE,
  ENUM_WORKFLOW_TYPE,
} from "@prisma/client";
import { NotificationWorkflow, SendGridTemplateDetails } from "@roadflex/types";
import { humanize } from "@roadflex/utils";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { AddWorkflowModal } from "../admin-modals";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { newPaginationTemplate } from "../datatable/pagination-templates/new-pagination-template";
import { TextInput } from "../inputs";

interface NotificationWorkflowUIProps {
  addedEmailAccounts: { id: string; email: string }[];
  notificationWorkflowData: NotificationWorkflow[];
  notificationWorkflowDataLoading: boolean;
  workFlowModal: boolean;
  setWorkFlowModal: (value: boolean) => void;
  selectedWorkflow: NotificationWorkflow[];
  setSelectedWorkflow: (value: NotificationWorkflow[]) => void;
  handleDeleteWorkflow: () => void;
  deletingWorkflow: boolean;
  sendGridTemplates: SendGridTemplateDetails[];
  selectedWorkflowType: ENUM_WORKFLOW_TYPE;
}

const NotificationWorkflowUI = (props: NotificationWorkflowUIProps) => {
  const {
    notificationWorkflowData,
    notificationWorkflowDataLoading,
    setWorkFlowModal,
    selectedWorkflow,
    setSelectedWorkflow,
    handleDeleteWorkflow,
    deletingWorkflow,
    selectedWorkflowType,
    workFlowModal,
    addedEmailAccounts,
    sendGridTemplates,
  } = props;

  const emptyJsonObject: Record<string, string> = {};
  const [selectedNotificationWorkflow, setSelectedNotificationWorkflow] =
    useState<NotificationWorkflow | null>(null);
  const [deleteWorkflow, setDeleteWorkflow] = useState(false);

  const handleDeleteWorkflowInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDeleteWorkflow(e.target.value === "Delete Step");
  };

  const notificationTypeTemplate = (rowData: NotificationWorkflow) => {
    return (
      <div className="flex items-center justify-between">
        <span className="text-sm text-brown-500 whitespace-nowrap">
          {rowData.notificationType.length > 0 &&
            rowData.notificationType.map(
              (role: ENUM_NOTIFICATION_TYPE, index: number) => (
                <span key={role}>
                  {humanize(role)}{" "}
                  {rowData.notificationType.length - 1 !== index && (
                    <span>, </span>
                  )}
                </span>
              ),
            )}
          {rowData.notificationType.length === 0 && "-"}
        </span>
      </div>
    );
  };

  const onViewWorkFlow = (rowData: NotificationWorkflow) => {
    setSelectedNotificationWorkflow(rowData);
    setWorkFlowModal(true);
  };

  const onEditTemplate = (rowData: NotificationWorkflow) => {
    return (
      <Button
        type={ButtonType.Button}
        size={ButtonSize.Small}
        variant={ButtonVariant.Secondary}
        onClick={() => onViewWorkFlow(rowData)}
      >
        View/Edit
      </Button>
    );
  };

  return (
    <div className="sm:rounded-md sm:overflow-hidden">
      <div className="flex items-center justify-between flex-1">
        <div className="flex justify-start">
          <div className="w-52">
            <TextInput
              defaultValue={""}
              type="text"
              placeholder="Type Delete Step"
              name="deleteStep"
              id="deleteStep"
              className="inline-flex items-center px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none placeholder:italic focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm w-52"
              onChange={(e) => handleDeleteWorkflowInputChange(e)}
            />
          </div>
          <Button
            variant={ButtonVariant.AppOrange}
            size={ButtonSize.AppSize}
            className="inline-flex items-center ml-3"
            disabled={!deleteWorkflow || notificationWorkflowDataLoading}
            loading={deletingWorkflow}
            onClick={handleDeleteWorkflow}
          >
            Confirm Delete
          </Button>
        </div>
        <div className="inline-block my-2 text-sm font-medium">
          <Button
            type={ButtonType.Button}
            size={ButtonSize.AppSize}
            variant={ButtonVariant.AppOrange}
            disabled={notificationWorkflowDataLoading}
            onClick={() => {
              setSelectedNotificationWorkflow({
                id: "",
                day: 0,
                notificationType: [],
                emailId: "",
                smsTemplate: "",
                variables: emptyJsonObject,
                sendgridTemplateType: ENUM_SENDGRID_TEMPLATE.NOTIFICATION_ONE,
                workflowType: selectedWorkflowType,
              });
              setWorkFlowModal(true);
            }}
          >
            Add Step
          </Button>
        </div>
      </div>
      <div className="flex flex-col mt-3 mb-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b shadow border-brown-200 sm:rounded-md">
              <DataTable
                className="!bg-white custom-table !text-brown-500 !border-brown-500"
                size="small"
                stripedRows
                value={notificationWorkflowData}
                loading={notificationWorkflowDataLoading}
                paginator
                rows={10}
                dataKey="id"
                sortField="day"
                sortOrder={1}
                paginatorTemplate={newPaginationTemplate}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10, 20, 50]}
                responsiveLayout="scroll"
                emptyMessage="No available records"
                style={{ fontFamily: "inherit" }}
                selectionMode="checkbox"
                selectionPageOnly
                selection={selectedWorkflow}
                onSelectionChange={(e) => setSelectedWorkflow(e.value)}
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3em" }}
                  headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                  bodyClassName="text-brown-500 !py-2 text-sm"
                ></Column>
                <Column
                  sortable
                  field="day"
                  header="Day"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                  bodyClassName="text-brown-500 !py-2 text-sm"
                />
                <Column
                  sortable
                  field="notificationType"
                  header="Notification Type"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                  bodyClassName="text-brown-500 !py-2 text-sm"
                  body={notificationTypeTemplate}
                />
                <Column
                  header="View/Edit Template"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500 text-sm"
                  bodyClassName="text-brown-500 !py-2 text-sm"
                  body={onEditTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      {workFlowModal && selectedNotificationWorkflow && (
        <AddWorkflowModal
          addedEmailAccounts={addedEmailAccounts}
          workflowData={notificationWorkflowData}
          selectedWorkflow={selectedNotificationWorkflow}
          workFlowModal={workFlowModal}
          setWorkFlowModal={setWorkFlowModal}
          sendGridTemplates={sendGridTemplates}
        ></AddWorkflowModal>
      )}
    </div>
  );
};

export default NotificationWorkflowUI;
