/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { TemplateProps } from "@roadflex/types";
import classNames from "classnames";
import { useFormik } from "formik";
import { Tooltip } from "primereact/tooltip";
import { Fragment, useRef, useState } from "react";
import * as Yup from "yup";

type TemplateModalProps = {
  savingTemplate: boolean;
  onSaveTemplate: ({ template }: TemplateProps) => void;
  templatePopup: boolean;
  setTemplatePopup: (templatePopup: boolean) => void;
  templateData: TemplateProps;
};
export const SmsTemplateModal = (props: TemplateModalProps) => {
  const {
    savingTemplate,
    templatePopup,
    onSaveTemplate,
    setTemplatePopup,
    templateData,
  } = props;

  const initValues = {
    template: templateData.template,
    id: templateData.id,
  };
  const cancelButtonRef = useRef(null);

  const [showEditButton, setShowEditButton] = useState(
    templateData.template.length > 0 || false,
  );
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    submitForm,
    setValues,
    values,
    touched,
    isSubmitting,
    errors,
    ...rest
  } = useFormik({
    initialValues: initValues,
    validationSchema: Yup.object().shape({
      template: Yup.string().required("Please enter a template"),
    }),
    onSubmit: async (value) => {
      onSaveTemplate({ template: value.template });
    },
  });
  return (
    <Transition.Root show={templatePopup} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setTemplatePopup}
      >
        <form onSubmit={handleSubmit} className="w-full">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-md shadow-xl sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-center text-gray-900"
                    >
                      {templateData?.template ? "Edit" : "Add"} Text Template
                    </Dialog.Title>
                    <div className="relative w-full mt-4">
                      <div className="mb-1 text-sm whitespace-nowrap">
                        Template*
                      </div>
                      <textarea
                        name="template"
                        value={values.template}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={showEditButton}
                        className={classNames(
                          touched?.template && errors?.template
                            ? "border-red-500 focus:border-red-500 border-2"
                            : "",
                          "block w-full disabled:opacity-50 pl-3 pr-8 py-2 border rounded focus:outline-none focus:ring-0 focus:shadow-none min-h-[200px]",
                        )}
                      />
                      {touched?.template && errors?.template && (
                        <>
                          <Tooltip
                            target=".template-error"
                            position="top"
                            className="red-tooltip"
                            content={errors.template}
                          ></Tooltip>

                          <div className="absolute h-[44px] bottom-0 right-0 pr-3 flex items-center cursor-pointer">
                            <ExclamationCircleIcon
                              className="w-5 h-5 text-red-500 template-error"
                              aria-hidden="true"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-row mt-5">
                    {showEditButton ? (
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm h-fit hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:col-start-2 sm:text-sm"
                        onClick={() => {
                          setShowEditButton(false);
                        }}
                      >
                        Edit
                      </button>
                    ) : (
                      <div className="flex w-full">
                        {templateData.template && (
                          <button
                            type="button"
                            className="inline-flex justify-center w-full px-4 py-2 mt-3 mr-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm h-fit hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                            onClick={() => {
                              setShowEditButton(true);
                              setValues(initValues);
                            }}
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          type="button"
                          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm h-fit hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:col-start-2 sm:text-sm"
                          disabled={isSubmitting || savingTemplate}
                          onClick={submitForm}
                        >
                          {savingTemplate ? (
                            <span>Saving...</span>
                          ) : (
                            <span>Save</span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col mt-2">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:col-start-1 sm:text-sm"
                      onClick={() => setTemplatePopup(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
};
