/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  InboxIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import { adminNotificationsOutreachFileSchema } from "@roadflex/constants";
import { adminOutreachFileUpload } from "@roadflex/web-api";
import classNames from "classnames";
import { useFormik } from "formik";
import { Tooltip } from "primereact/tooltip";
import { Fragment, useRef } from "react";
import { toast } from "react-toastify";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";

export type UploadOutreachFileProps = {
  outreachFileUploadPopup: boolean;
  setOutreachFileUploadPopup: (outreachFileUploadPopup: boolean) => void;
  refetchOutreachData: () => void;
};
export const UploadOutreachFile = (props: UploadOutreachFileProps) => {
  const {
    outreachFileUploadPopup,
    setOutreachFileUploadPopup,
    refetchOutreachData,
  } = props;
  const cancelButtonRef = useRef(null);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    isSubmitting,
    errors,
    setFieldValue,
    resetForm,
    ...rest
  } = useFormik({
    initialValues: { description: "", outreachFile: null },
    validationSchema: adminNotificationsOutreachFileSchema,
    onSubmit: async (value) => {
      try {
        await adminOutreachFileUpload(
          value.outreachFile as File,
          value.description,
        );
        toast.success("File uploaded successfully");
        refetchOutreachData();
        resetForm();
        setOutreachFileUploadPopup(false);
      } catch (err) {
        toast.error(err.message || "Error uploading file");
      }
    },
  });
  return (
    <Transition.Root show={outreachFileUploadPopup} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOutreachFileUploadPopup}
      >
        <form onSubmit={handleSubmit} className="w-full">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-md shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                    <InboxIcon
                      className="w-6 h-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-center text-gray-900"
                    >
                      Upload Outreach File
                    </Dialog.Title>
                    <div className="relative w-full">
                      <div className="mb-1 text-sm whitespace-nowrap">
                        Description*
                      </div>
                      <input
                        type="description"
                        name="description"
                        required
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classNames(
                          touched?.description && errors?.description
                            ? "border-red-500 focus:border-red-500 border-2"
                            : "",
                          "block w-full pl-3 pr-8 py-2 border rounded focus:outline-none focus:ring-0 focus:shadow-none",
                        )}
                      />
                      {touched?.description && errors?.description && (
                        <>
                          <Tooltip
                            target=".description-error"
                            position="top"
                            className="red-tooltip"
                            content={errors.description}
                          ></Tooltip>

                          <div className="absolute h-[44px] bottom-0 right-0 pr-3 flex items-center cursor-pointer">
                            <ExclamationCircleIcon
                              className="w-5 h-5 text-red-500 description-error"
                              aria-hidden="true"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="relative w-full mt-4">
                      <div className="relative w-full mt-4 mb-2">
                        <div className="mb-1 text-sm whitespace-normal">
                          Please upload the Outreach File*
                        </div>

                        <div>
                          <div className="flex items-center justify-center w-full mx-auto text-gray-500 bg-gray-200 rounded-lg cursor-pointer">
                            <label className="flex flex-col w-full h-32 cursor-pointer ">
                              <div className="flex flex-col items-center justify-center pt-7">
                                <UploadIcon
                                  className="w-10 h-10 text-gray-500"
                                  aria-hidden="true"
                                />
                                <p className="pt-1 text-sm tracking-wider group-hover:text-gray-600">
                                  Browse
                                </p>
                                <div className="w-full px-2 overflow-y-hidden text-center max-h-6">
                                  {values.outreachFile instanceof File &&
                                    values.outreachFile?.name}
                                </div>
                              </div>
                              <input
                                type="file"
                                id="outreachFile"
                                onChange={(event) => {
                                  setFieldValue(
                                    "outreachFile",
                                    event?.target?.files
                                      ? event?.target?.files[0]
                                      : "",
                                  );
                                }}
                                className="opacity-0"
                                onBlur={handleBlur}
                                accept=".csv"
                              />
                            </label>
                          </div>
                        </div>

                        {touched.outreachFile && (
                          <div className="mt-1 text-sm text-red-500">
                            {errors.outreachFile}
                          </div>
                        )}
                        <div className="flex items-center justify-between mt-1 text-sm text-gray-500">
                          <span>
                            Accepted file type: csv only (Maximum size: 50MB)
                          </span>{" "}
                          <span className="flex items-center ml-4 ">
                            <i className="mr-1 fa fa-lock"></i> secure
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.SecondaryOutline}
                    onClick={() => setOutreachFileUploadPopup(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Submit}
                    size={ButtonSize.Normal}
                    variant={ButtonVariant.Orange}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    className="inline-flex"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
};
