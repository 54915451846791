import { useMutation, useQuery } from "@apollo/client";
import { ENUM_SENDGRID_TEMPLATE, ENUM_WORKFLOW_TYPE } from "@prisma/client";
import {
  DELETE_COLLECTION_WORKFLOW,
  READ_ADMIN_OUTBOUND_EMAILS,
  READ_COLLECTION_WORKFLOW_SETTINGS,
  READ_DELINQUENT_DEFAULT_DATA,
  READ_NOTIFICATION_WORKFLOW,
  UPDATE_BUSINESS_COLLECTION_ADMIN,
  UPDATE_COLLECTION_WORKFLOW_SETTINGS,
} from "@roadflex/graphql";
import { NotificationWorkflow, SendGridTemplateDetails } from "@roadflex/types";
import { useFormik } from "formik";

type updateBusinessCollectionsAdminProps = {
  onSubmit: (res: any, err: any) => void;
  initialValues: {
    adminId: string;
    userEmail: string;
  };
};

type updateCollectionWorkflowSettingProps = {
  onSubmit: (res: any, err: any) => void;
  initialValues: {
    id: string;
    isEmailEnabled: boolean;
    isSmsEnabled: boolean;
  };
};

type deleteCollectionWorkflowSettingProps = {
  onSubmit: (res: any, err: any) => void;
  initialValues: {
    collectionIds: string[];
  };
};

export function useReadAdminOutboundEmails() {
  const { data, loading, ...rest } = useQuery<{
    readAdminOutboundEmails: {
      id: string;
      email: string;
    }[];
  }>(READ_ADMIN_OUTBOUND_EMAILS, {
    fetchPolicy: "no-cache",
  });
  return {
    data,
    loading,
  };
}
export function useReadCollectionWorkflowSetting() {
  const { data, refetch, loading, ...rest } = useQuery<{
    readCollectionWorkflowSetting: {
      id: string;
      isEmailEnabled: boolean;
      isSmsEnabled: boolean;
    };
  }>(READ_COLLECTION_WORKFLOW_SETTINGS, {
    fetchPolicy: "no-cache",
  });

  return {
    data,
    ...rest,
  };
}
export const useUpdateCollectionWorkflowSetting = ({
  onSubmit,
  initialValues,
}: updateCollectionWorkflowSettingProps) => {
  const [updateCollectionWorkflowSettingFn] = useMutation<{
    updateCollectionWorkflowSetting: {
      code: string;
      message: string;
    };
  }>(UPDATE_COLLECTION_WORKFLOW_SETTINGS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [READ_COLLECTION_WORKFLOW_SETTINGS],
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await updateCollectionWorkflowSettingFn({
          variables: { data: value },
        });
        if (res?.data?.updateCollectionWorkflowSetting) {
          onSubmit(res?.data?.updateCollectionWorkflowSetting, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
export function useReadCollectionWorkflow(
  workflowType: ENUM_WORKFLOW_TYPE,
  sendgridTemplateType: ENUM_SENDGRID_TEMPLATE,
) {
  const { data, refetch, loading, ...rest } = useQuery<{
    readNotificationWorkflow: {
      code: string;
      message: string;
      notificationWorkflow: NotificationWorkflow[];
      sendGridTemplates: SendGridTemplateDetails[];
    };
  }>(READ_NOTIFICATION_WORKFLOW, {
    variables: {
      data: {
        workflowType,
        sendgridTemplateType,
      },
    },
    fetchPolicy: "no-cache",
  });
  return {
    data,
    loading,
    ...rest,
  };
}
export const useDeleteCollectionWorkflow = ({
  onSubmit,
  initialValues,
}: deleteCollectionWorkflowSettingProps) => {
  const [deleteCollectionWorkflowSettingFn] = useMutation<{
    deleteCollectionWorkflow: {
      code: string;
      message: string;
    };
  }>(DELETE_COLLECTION_WORKFLOW, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [READ_NOTIFICATION_WORKFLOW],
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await deleteCollectionWorkflowSettingFn({
          variables: { data: value },
        });
        if (res?.data?.deleteCollectionWorkflow) {
          onSubmit(res?.data?.deleteCollectionWorkflow, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
export function useReadDelinquentAndDefaultData() {
  const {
    data: delinquentAndDefaultData,
    refetch: delinquentAndDefaultRefetch,
    loading: delinquentAndDefaultLoading,
    ...rest
  } = useQuery<{
    readDelinquentDefaultData: {
      delinquent: any;
      default: any;
      code: string;
      message: string;
    };
  }>(READ_DELINQUENT_DEFAULT_DATA, {
    fetchPolicy: "no-cache",
  });

  return {
    delinquentAndDefaultData,
    delinquentAndDefaultRefetch,
    delinquentAndDefaultLoading,
    ...rest,
  };
}
export const useUpdateBusinessCollectionsAdmin = ({
  onSubmit,
  initialValues,
}: updateBusinessCollectionsAdminProps) => {
  const [updateBusinessCollectionAdminFn] = useMutation<{
    updateBusinessCollectionAdmin: {
      code: string;
      message: string;
    };
  }>(UPDATE_BUSINESS_COLLECTION_ADMIN);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await updateBusinessCollectionAdminFn({
          variables: { data: value },
        });
        if (res?.data?.updateBusinessCollectionAdmin) {
          onSubmit(res?.data?.updateBusinessCollectionAdmin, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
