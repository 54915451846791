/** @format */
import { useMutation } from "@apollo/client";
import { AddUserCreditByAdminMutation } from "@roadflex/graphql";
import { useFormik } from "formik";

type Values = {
  userId: any;
  userLeadId: any;
  credit: any;
  details: any;
};

type UseApprovalProps = {
  initialValues: Values;
  onSubmit: (val: any, err: any) => void;
};

export const useAddUserCreditByAdmin = ({
  onSubmit,
  initialValues,
}: UseApprovalProps) => {
  const [updateUserAdditionalLimit] = useMutation(AddUserCreditByAdminMutation);

  const {
    handleChange: handleAddUserCreditByAdminChange,
    handleSubmit: handleAddUserCreditByAdminSubmit,
    values: addUserCreditByAdminValues,
    isSubmitting: isAddUserCreditByAdminSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const data = { ...values };
      try {
        const res = await updateUserAdditionalLimit({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    handleAddUserCreditByAdminChange,
    handleAddUserCreditByAdminSubmit,
    addUserCreditByAdminValues,
    isAddUserCreditByAdminSubmitting,
    ...rest,
  };
};
