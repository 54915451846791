/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAdminActivityLogs } from "@roadflex/react-hooks";
import { AdminType, UserActivityLogType } from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import { activityLogMapper } from "@roadflex/web-lib";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";

interface ActivityLogsModalProps {
  admin: AdminType;
  openActivityLogsModal: boolean;
  setOpenActivityLogsModal: (value: boolean) => void;
}

export const ActivityLogsModal = ({
  admin,
  openActivityLogsModal,
  setOpenActivityLogsModal,
}: ActivityLogsModalProps) => {
  const {
    adminActivityLogsData,
    adminActivityLogsRefetch,
    adminActivityLogsLoading,
  } = useAdminActivityLogs({ adminId: admin?.id || "" });

  useEffect(() => {
    if (openActivityLogsModal) {
      if (!admin?.id) {
        toast.error(
          "Error: Admin not found. Refresh the page or sign in again.",
        );
      }
    }
  }, [openActivityLogsModal]);

  let { adminActivityLogs } = adminActivityLogsData || {};

  adminActivityLogs =
    adminActivityLogs &&
    adminActivityLogs.map((log: UserActivityLogType) => {
      return activityLogMapper(log);
    });

  const [activityLogsFilterConfig, setActivityLogsFilterConfig] = useState({
    activity: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    createdAt: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    activityTypeString: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  });

  const dateTemplate = (rowData: UserActivityLogType) => {
    return (
      <span className="text-sm text-gray-500 whitespace-nowrap">
        {rowData.createdAt && (
          <time>
            {convertISOStringToDate(new Date(rowData.createdAt).toISOString())}
          </time>
        )}
      </span>
    );
  };
  const activityTemplate = (rowData: UserActivityLogType) => {
    return (
      <div className="text-sm text-gray-500 break-all">{rowData.activity}</div>
    );
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  return (
    <Dialog
      header={`Activity logs for ${admin?.firstName} ${admin?.lastName}`}
      visible={openActivityLogsModal}
      style={{ width: "80vw", fontFamily: "inherit" }}
      footer={<div></div>}
      onHide={() => setOpenActivityLogsModal(false)}
    >
      <div className="border-b border-gray-200 shadow sm:rounded-md">
        <DataTable
          size="small"
          stripedRows
          value={adminActivityLogs}
          paginator
          rows={10}
          dataKey="id"
          filters={activityLogsFilterConfig}
          sortField="createdAt"
          sortOrder={-1}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rowsPerPageOptions={[10, 20, 50]}
          responsiveLayout="scroll"
          emptyMessage="No available records"
          paginatorTemplate={newPaginationTemplate}
          loading={adminActivityLogsLoading}
          style={{ fontFamily: "inherit" }}
          className="!bg-white custom-table !text-brown-500 !border-brown-500"
        >
          <Column
            filter
            sortable
            filterPlaceholder="Search by activity"
            field="activity"
            header="Activity"
            body={activityTemplate}
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
            headerClassName="!bg-white !text-brown-500 !border-brown-500"
          />
          <Column
            filter
            sortable
            filterPlaceholder="Search by type"
            field="activityTypeString"
            header="Type"
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base capitalize"
            headerClassName="!bg-white !text-brown-500 !border-brown-500"
          />
          <Column
            filter
            filterElement={dateFilterTemplate}
            sortable
            dataType="date"
            filterField="createdAt"
            field="createdAt"
            header="Time"
            bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
            headerClassName="!bg-white !text-brown-500 !border-brown-500"
            body={dateTemplate}
          />
        </DataTable>
      </div>
    </Dialog>
  );
};
